<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ invalid, pristine }"
    >
      <b-modal
        id="modal-modify-change-passengers-info"
        :title="$t('reservation.editInfoPaxList')"
        title-class="text-airline font-medium-4 fw-600"
        centered
        size="lg"
        no-close-on-backdrop
        no-enforce-focus
        @show="showModalPassengersInfo"
      >
        <template #modal-footer="{close}">
          <b-button
            variant="outline-secondary"
            class="rounded-pill"
            @click="close()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient"
            pill
            :disabled="invalid||pristine"
            @click="submitAndCheckPriceHandle"
          >
            {{ $t('reservation.historyActions.CALCULATE_PRICE') }}
          </b-button>
        </template>

        <div v-if="dataToEdit">
          <b-alert
            variant="warning"
            show
            class="p-75"
          >
            <div class="text-danger">
              Lưu ý : <br>
              - Chỉ được đổi tên hành khách một lần không quá 2 ký tự (miễn phí). <br>
              <!-- - Mỗi lần thao tác chỉ sửa thông tin của 1 hành khách. -->
            </div>
          </b-alert>

          <b-row
            v-for="pax of dataToEdit"
            :key="pax.paxId"
            class="mx-0 mb-75 py-50 border-secondary rounded-lg passenger_row"
            no-gutters
          >
            <b-col
              cols="12"
              :class="`d-flex-between ${pax.isEdit ? 'mb-75' : ''}`"
            >
              <span class="fw-700">
                {{ pax.dataShow }}
              </span>
              <b-button
                :variant="pax.isEdit ? 'outline-danger' : 'primary'"
                size="sm"
                class="rounded-lg mx-25"
                @click="handleEditPassenger(pax.paxId)"
              >
                {{ pax.isEdit ? 'Huỷ' : 'Sửa' }}
              </b-button>
            </b-col>

            <!-- ANCHOR - Honorific -->
            <!-- <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Honorific"
                :rules="['VJ'].includes(source) ? 'required' : ''"
              >
                <b-form-group
                  :label="$t('reservation.Title')"
                  label-for=""
                >
                  <v-select
                    id="Honorific"
                    v-model="dataToEdit.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      (dataToEdit.paxType !== 'ADULT')
                        ? honorificOptions.children
                        : honorificOptions.adult"
                    :state="getValidationState(validationContext) === false ? false : null"
                    label="text"
                    :clearable="false"
                    :disabled="true"
                    :placeholder="`${$t('reservation.Title')} (*)`"
                  >
                    <template #option="data">
                      <span class="font-medium-1 fw-600">
                        {{ $t(`reservation.titleOptions.${dataToEdit.paxType}.${data.text}`) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span class="font-medium-1 fw-600">
                        {{ $t(`reservation.titleOptions.${dataToEdit.paxType}.${data.text}`) }}
                      </span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <!-- ANCHOR - Gender -->
            <!-- <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Gender"
              >
                <b-form-group label-for="Gender">
                  <template #label>
                    <span>
                      {{ $t('reservation.Title') }}
                    </span>
                  </template>

                  <v-select
                    id="Gender"
                    v-model="dataToEdit.gender"
                    :options="GENDER_OPTIONS"
                    :state="getValidationState(validationContext) === false ? false : null"
                    label="text"
                    :clearable="false"
                    :placeholder="$t('reservation.Gender')"
                  >
                    <template #option="data">
                      <span class="font-medium-1 fw-600">
                        {{ $t(`reservation.genderOptions.${dataToEdit.paxType}.${data.text}`) }}
                      </span>
                    </template>
                    <template #selected-option="data">
                      <span class="font-medium-1 fw-600">
                        {{ $t(`reservation.genderOptions.${dataToEdit.paxType}.${data.text}`) }}
                      </span>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <template v-if="pax.isEdit">
              <!-- ANCHOR - lastName -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Last Name"
                  :rules="`required|compareCountChange:${pax.defaultLastName},${pax.firstName},${pax.defaultFirstName}`"
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation.lastName') }}
                        <span class="text-danger ml-25">(*)</span>
                      </span>
                    </template>
                    <b-form-input
                      :id="`lastName${pax.paxId}`"
                      v-model="pax.lastName"
                      lazy-formatter
                      :formatter="formatterNameOnly"
                      class="font-medium-1 fw-600 text-uppercase"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR - firstName -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="First Name"
                  :rules="`required|compareCountChange:${pax.defaultFirstName},${pax.lastName},${pax.defaultLastName}`"
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation.firstName') }}
                        <span class="text-danger ml-25">(*)</span>
                      </span>
                    </template>

                    <b-form-input
                      :id="`firstName${pax.paxId}`"
                      v-model="pax.firstName"
                      lazy-formatter
                      :formatter="formatterNameOnly"
                      class="font-medium-1 fw-600 text-uppercase"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR - Birthday -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('reservation[\'DoB\']')"
                  :rules="`required|ageGroup:OW,${getBookingData.itineraries[0][0].departure.at},${pax.paxType}`"
                  vid="passenger-birthday"
                >
                  <b-form-group class="form_label">
                    <template #label>
                      <span>
                        {{ $t('reservation[\'DoB\']') }}
                        <span class="text-danger ml-25">(*)</span>
                        <!-- <small class="text-italic">(định dạng <span class="text-danger">DD/MM/YYYY</span>)</small> -->
                      </span>
                    </template>
                    <flat-pickr
                      :id="`passenger-birthday${pax.paxId}`"
                      v-model="pax.birthday"
                      class="d-none"
                    />
                    <div
                      class="form-control p-0"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        :id="`passenger-birthday${pax.paxId}`"
                        v-model="pax.birthday"
                        :name="$t('reservation[\'DoB\']')"
                        class="form-control px-50 py-0"
                        :config="{
                            ...configFlatPickr,
                            altInputClass: 'form-control input px-50 py-0 border-transparent',
                        }"
                        :placeholder="$t('reservation[\'formatDoB\']')"
                      />
                    </div>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>
          </b-row>
        </div>

        <div
          v-else
          class="d-flex-center"
        >
          <b-spinner variant="primary" />
        </div>
      </b-modal>
    </validation-observer>

    <!-- SECTION Modal 2: submit-change -->
    <b-modal
      id="modal-modify-change-passengers-info-confirm-price"
      :title="$t('reservation.confirmInfoChange')"
      title-class="text-airline font-medium-3 fw-600"
      centered
      no-close-on-backdrop
      no-enforce-focus
      @show="showPriceHandle"
      @hide="hidePriceHandle"
    >
      <template #modal-footer="{cancel}">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="!isConfirm || (totalSeconds === 0) || !canChangeInfo"
          @click="submitChangeInfoHandle"
        >
          <div class="d-flex-center px-25">
            {{ $t('reservation.confirm') }}
          </div>
        </b-button>
      </template>

      <div v-if="newPaxLists">
        <b-table
          class="position-relative rounded"
          :items="newPaxLists"
          responsive
          :fields="tableColumns"
          show-empty
          :empty-text="$t('noMatchingResult')"
          outlined
          small
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column})`]
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`reservation.${column}`) }}
            </span>
          </template>

          <template #cell(no)="data">
            <span class="font-weight-bold">{{ data.index + 1 }}</span>
          </template>
          <template #cell(firstName)="{item}">
            <span class="font-weight-bold">{{ item.firstName }}</span>
          </template>
          <template #cell(lastName)="{item}">
            <span>{{ item.lastName }}</span>
          </template>
          <template #cell(birthday)="{item}">
            <span>{{ item.birthday ? formatDateMonth(item.birthday) : '-' }}</span>
          </template>
        </b-table>

        <BCard
          v-if="priceChange"
          header-bg-variant="light-success"
          header-class="py-50"
          body-class="py-50 px-25"
          class="border mb-50"
        >
          <div class="mt-1">
            <template v-if="canChangeInfo">
              <b-alert
                v-if="totalSeconds > 0"
                show
                class="m-50 py-50 text-center"
                :variant="canChangeInfo ? 'success' : 'danger'"
              >
                <span class="fw-700">{{ canChangeInfo ? $t("reservation[\'Can to change\']") : $t("reservation[\'Please contact booker for support\']") }}</span>
              </b-alert>

              <b-alert
                v-if="totalSeconds > 0"
                show
                class="m-50 py-50 text-center"
                variant="danger"
              >
                {{ $t("reservation[\'Remaining time\']") }}: <span class="text-body">{{ convertTotalSecondToMinutesAndSecond(totalSeconds) }} </span>
              </b-alert>

              <div v-else>
                <div class="text-danger text-center font-medium-2 fw-700">
                  {{ $t("reservation[\'Confirmation time has expired, please try again\']") }}
                </div>
              </div>
            </template>

            <template v-else>
              <b-alert
                show
                class="m-50 py-50 text-center"
                variant="danger"
              >
                <span class="fw-700">{{ $t("reservation[\'Please contact booker for support\']") }}</span>
              </b-alert>
            </template>
          </div>

          <BRow class="mx-0 mb-25">
            <BCol
              cols="12"
              class="text-right fw-600"
            >
              Phí đổi:
              <span class="fw-800 ml-75">
                {{ formatCurrency(priceChange.fee) }} ({{ priceChange.currency || '' }})
              </span>
            </BCol>
          </BRow>
        </BCard>

        <IAmConfirm :is-confirm.sync="isConfirm" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BAlert,
  BFormInvalidFeedback,
  BTable,
} from 'bootstrap-vue'
import {
  ref, computed, watch, set,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue'

import { formatCurrency, formatDateMonth } from '@/@core/utils/filter'
import IAmConfirm from '@/components/IAmConfirm.vue'
import i18n from '@/libs/i18n'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import store from '@/store'

import { validatorPaymentPassword } from '@core/utils/validations/validators'
import formValidation from '@core/comp-functions/forms/form-validation'
import { formatterNameOnly } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,
    BFormInput,
    BRow,
    BCard,
    BCol,
    BButton,
    BTable,
    BFormInvalidFeedback,
    BAlert,

    ValidationProvider,
    ValidationObserver,

    IAmConfirm,
    flatPickr,
  },
  setup(props, { root }) {
    const {
      getBookingData,
      paxInfoPrice,
      modifyPaxInfo,
      delay,
    } = useReservationHandle()
    const { toastError } = useToast()

    const tableColumns = ['no', 'lastName', 'firstName', 'birthday']
    const newPaxLists = ref([])
    const priceChange = ref(null)
    const isConfirm = ref(false)
    const isHideSomePriceField = ref(true)
    const dataToEdit = ref(null)
    const bookingId = ref(null)
    const payloadData = ref(null)

    const locale = computed(() => i18n.locale).value
    const source = computed(() => getBookingData.value?.source)
    const dataPax = computed(() => getBookingData.value?.paxLists?.filter(p => !['INFANT'].includes(p.paxType)) || [])
    const canChangeInfo = computed(() => priceChange.value?.fee === 0)

    // const GENDER_OPTIONS = ['FEMALE', 'MALE']
    // const honorificOptions = {
    //   adult: ['MR', 'MRS', 'MS'],
    //   children: ['MISS', 'MSTR'],
    // }

    // Modal 1
    const resetContactDataToEdit = () => {
      dataToEdit.value = dataPax.value.map((pax, index) => ({
        ...pax,
        isEdit: false,
        paxId: pax.paxId || `${index + 1}`,
        dataShow: `${pax.lastName} / ${pax.firstName} ${pax.birthday ? `(${formatDateMonth(pax.birthday)})` : ''}`,
        firstName: pax.firstName || '',
        lastName: pax.lastName || '',
        birthday: pax.birthday || '',
        defaultFirstName: pax.firstName || '',
        defaultLastName: pax.lastName || '',
        defaultFullName: `${pax.lastName} ${pax.firstName}`,
        defaultBirthday: pax.birthday || '',
        title: pax.title || '',
        paxType: pax.paxType || '',
        parentPaxId: pax.parentPaxId || '',
        familyId: pax.familyId || '',
        pricingInfo: {
          currency: 'string',
          netFare: 0,
          tax: 0,
          total: 0,
        },
        gender: pax.gender || '',
      }))
    }

    const { refFormObserver, getValidationState } = formValidation(resetContactDataToEdit)

    const configFlatPickr = {
      dateFormat: 'Y-m-d',
      minDate: '1.1.1920',
      maxDate: 'today',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    }

    // Modal 1
    function handleEditPassenger(paxId) {
      const pax = dataToEdit.value[dataToEdit.value.findIndex(p => p.paxId === paxId)]
      set(pax, 'isEdit', !pax.isEdit)

      // --- NOTE: EDIT ONCE PAX ---
      // dataToEdit.value = dataToEdit.value.map(p => ({
      //   ...p,
      //   lastName: p.defaultLastName,
      //   firstName: p.defaultFirstName,
      //   birthday: p.defaultBirthday,
      //   isEdit: p.paxId === paxId && !p.isEdit,
      // }))
    }

    const CHARACTER_EDIT_COUNT = 2

    // Modal 1
    function validateCountModified(defaultValue, modifyValue) {
      const len1 = defaultValue.length
      const len2 = modifyValue.length

      // "Edit Distance"
      const dp = Array(len1 + 1).fill(null).map(() => Array(len2 + 1).fill(0))

      for (let i = 0; i <= len1; i += 1) {
        dp[i][0] = i
      }
      for (let j = 0; j <= len2; j += 1) {
        dp[0][j] = j
      }
      for (let i = 1; i <= len1; i += 1) {
        for (let j = 1; j <= len2; j += 1) {
          if (defaultValue[i - 1] === modifyValue[j - 1]) { // Nếu các ký tự giống nhau, không cần thay đổi
            dp[i][j] = dp[i - 1][j - 1]
          } else {
            // 1. Thay thế: dp[i - 1][j - 1] + 1
            // 2. Chèn: dp[i][j - 1] + 1
            // 3. Xóa: dp[i - 1][j] + 1
            dp[i][j] = Math.min(dp[i - 1][j - 1], dp[i][j - 1], dp[i - 1][j]) + 1
          }
        }
      }
      return dp[len1][len2]
    }

    // Modal 1
    extend('compareCountChange', {
      validate(value, { defaultValue, other, defaultOther }) {
        const valueChangeCount = validateCountModified(defaultValue, value.toUpperCase())
        const otherChangeCount = validateCountModified(defaultOther, other.toUpperCase())

        const validate = (valueChangeCount + otherChangeCount) <= CHARACTER_EDIT_COUNT
        return validate || 'Tên hành khách chỉ được sửa tối đa 2 ký tự'
      },
      params: ['defaultValue', 'other', 'defaultOther'],
      // params: ['field', 'otherField', 'fullName'],
    })

    // Modal 1
    function showModalPassengersInfo() {
      resetContactDataToEdit()
      payloadData.value = null
    }

    // Modal 1: submit
    async function submitAndCheckPriceHandle() {
      const passengers = dataToEdit.value.filter(pax => pax.isEdit)
      payloadData.value = {
        paxUpdates: passengers.map(pax => ({
          paxInfo: {
            firstName: pax.firstName,
            lastName: pax.lastName,
            title: pax.title,
            paxType: pax.paxType,
            birthday: pax?.birthday || null,
            paxId: pax.paxId,
            parentPaxId: pax?.parentPaxId || null,
            familyId: pax?.familyId || pax.parentPaxId,
            pricingInfo: {
              currency: 'VND',
              netFare: 0,
              tax: 0,
              total: 0,
            },
          },
          operation: 'UPDATE',
          id: 'string',
        })),
      }

      newPaxLists.value = []
      priceChange.value = null
      bookingId.value = null

      this.$bvModal.show('modal-api-loading')
      paxInfoPrice(getBookingData.value, payloadData.value)
        .then(async res => {
          // if (res.chargeAmount && !isEmpty(res.paxLists) && res.bookingId && (res.chargeAmount?.oldPrice === res.chargeAmount?.newPrice)) {
          if (res.chargeAmount && !isEmpty(res.paxLists)) {
            newPaxLists.value = res.paxLists
            priceChange.value = res.chargeAmount
            bookingId.value = res.bookingId

            await delay(200)
            this.$bvModal.show('modal-modify-change-passengers-info-confirm-price')
          } else {
            toastError({
              title: 'messagesList.error',
              content: 'reservation[\'Cannot be changed, please check the company regulations or contact the booker for processing\']',
            })
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    const totalSeconds = ref(0)
    let interval = null

    // Modal 2: Show
    function showPriceHandle() {
      isConfirm.value = false

      if (canChangeInfo.value) {
        totalSeconds.value = 870 // 14m30s
      }
    }

    // Modal 2: Hide
    function hidePriceHandle() {
      isHideSomePriceField.value = true
      totalSeconds.value = 0
      priceChange.value = null

      if (interval) {
        clearInterval(interval)
        interval = null
      }
    }

    // Modal 2
    watch(totalSeconds, newVal => {
      if (newVal > 0 && !interval) {
        interval = setInterval(() => {
          if (totalSeconds.value > 0) {
            totalSeconds.value -= 1
          } else {
            clearInterval(interval)
            interval = null
          }
        }, 1000)
      }
    })

    // Modal 2
    function convertTotalSecondToMinutesAndSecond(val) {
      const minutes = Math.floor(val / 60)
      const seconds = val % 60
      return `${minutes > 0 ? `${minutes}m` : ''}${seconds}s`
    }

    // Modal 2
    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    // NOTE Modal 2: submit change
    async function submitChangeInfoHandle() {
      if (!canChangeInfo.value || totalSeconds.value === 0) {
        toastError({
          title: 'messagesList.error',
          content: 'reservation[\'Cannot be changed, please check the company regulations or contact the booker for processing\']',
        })
        return
      }

      const isPaidBooking = ['PAID'].includes(getBookingData.value.status)
      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPaidBooking) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          allowOutsideClick: true,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      const updateData = {
        ...payloadData.value,
        isPaymentImmediate: (getMeEnablePaymentPassword.value && isPaidBooking) ?? false,
        paymentPassword: paymentPassword?.value || '',
        bookingId: bookingId.value,
      }

      this.$bvModal.show('modal-api-loading')
      modifyPaxInfo(getBookingData.value, updateData)
        .then(() => {
          Array.from(window.document.querySelectorAll('.modal.show'))
            .map(el => el.id)
            .forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => this.$bvModal.hide('modal-api-loading'))
    }

    return {
      getBookingData,
      dataToEdit,
      // honorificOptions,
      // GENDER_OPTIONS,
      canChangeInfo,
      formatterNameOnly,
      showModalPassengersInfo,
      showPriceHandle,
      priceChange,
      newPaxLists,
      formatCurrency,
      submitAndCheckPriceHandle,
      submitChangeInfoHandle,
      formatDateMonth,
      source,
      isEmpty,
      refFormObserver,
      getValidationState,
      isConfirm,
      tableColumns,
      isHideSomePriceField,
      configFlatPickr,
      handleEditPassenger,
      hidePriceHandle,
      totalSeconds,
      convertTotalSecondToMinutesAndSecond,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form_label {
  legend {
    padding-bottom: 4px !important;
  }
}

.passenger_row {
  > div {
    padding: 0 7px !important;
  }
}
</style>
